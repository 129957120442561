/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction, SerializedError } from '@reduxjs/toolkit';

import ROUTES from '@/constants/routes';
import { createNavigationParams } from '@/state/reducers/navigationSlice';
import { handleLogout } from '@/state/tokenHelper';
import { navigate } from '@/utils/navigation';
import { fetchShowsServerSide } from '../../../next/src/utils/server-functions/fetchShowsServerSide';
import type { Event } from '../types/contentful';

export interface EventsState {
  status: 'NOT_FETCHED' | 'FETCHED' | 'IN_PROGRESS' | 'FAILED';
  events: Event[];
  error?: SerializedError;
}

export const eventsSliceInitialState = {
  status: 'NOT_FETCHED',
  events: [],
} as EventsState;

export const fetchShows = createAsyncThunk(
  'events/fetchShows',
  async (payload: { auth: { jwt: string } }, thunkAPI) => {
    const response = await fetchShowsServerSide(payload);
    if (response.error) {
      if (response.error.validJWT === false) {
        handleLogout(thunkAPI.dispatch);
        thunkAPI.dispatch(
          createNavigationParams({
            state: {
              loginReason: 'ERROR',
              returnTo: window.location.pathname,
            },
          }),
        );
        navigate(ROUTES.login);
      }

      return thunkAPI.rejectWithValue({
        error: {
          status: 'UNEXPECTED',
          message: response.error,
        },
      });
    }
    return response;
  },
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState: eventsSliceInitialState,
  reducers: {
    clear(state) {
      state.status = 'NOT_FETCHED';
      state.events = [];
      state.error = undefined;
    },
    addEvent(state, action: PayloadAction<Event>) {
      state.events = [action.payload, ...state.events];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShows.pending, (state) => {
      state.status = 'IN_PROGRESS';
      state.error = undefined;
      state.events = [];
    });
    builder.addCase(fetchShows.fulfilled, (state, action) => {
      state.status = 'FETCHED';
      state.events = action.payload;
    });
    builder.addCase(fetchShows.rejected, (state, action) => {
      state.status = 'FAILED';
      state.error = action.error;
    });
  },
});

export const eventsActions = eventsSlice.actions;
