'use client';

import { useEffect, useState } from 'react';
import { fetchPlatformMessage } from '@/reducers/platformMessageSlice';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { getItemFromLocalStorage, setItemInLocalStorage } from '../../state/localStorage';
import Icon from '../Icon';

// Local storage's value name
const LATEST_PLATFORM_MESSAGE_DATE = 'latestPlatformMessageDate';
export const ErrorMessageBanner = () => {
  const dispatch = useAppDispatch();
  const [previousUpdatedAt, setPreviousUpdatedAt] = useState(getItemFromLocalStorage(LATEST_PLATFORM_MESSAGE_DATE));
  const {
    description,
    updatedAt
  } = useAppSelector(state => state.platformMessages);
  const [showErrorBanner, setShowErrorBanner] = useState(false);

  // get any web platform message
  useEffect(() => {
    dispatch(fetchPlatformMessage({
      platform: 'web'
    }));
  }, [dispatch]);
  useEffect(() => {
    const hideErrorBanner = !updatedAt || !description || previousUpdatedAt === updatedAt;
    setShowErrorBanner(!hideErrorBanner);
  }, [description, previousUpdatedAt, updatedAt]);
  const handleHideMessage = () => {
    setItemInLocalStorage(LATEST_PLATFORM_MESSAGE_DATE, updatedAt);
    setShowErrorBanner(false);
    setPreviousUpdatedAt(updatedAt);
  };

  // if there is no updatedAt or description or the user has already seen the message don't show it again
  if (!showErrorBanner) return null;
  return <span className="platform-message-banner py-1" data-sentry-component="ErrorMessageBanner" data-sentry-source-file="ErrorMessageBanner.tsx">
      <p className="flex-1 body-text">{description}</p>
      <Icon icon="oa-close" className="close-icon" onClick={handleHideMessage} data-sentry-element="Icon" data-sentry-source-file="ErrorMessageBanner.tsx" />
    </span>;
};